.App {
 
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: center;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.phone
{
  text-align: right;
  position: sticky;
  top: 0;
  color: darkgoldenrod;
 
}
.lc li::first-line{
font-weight: bold;
color: darkgoldenrod;
}
.q
{
  font-weight: bolder;
  color: darkgoldenrod;
  padding: 10px;
}
.a{
  padding: 10px;
}
.footers a {color:#696969;}
.footers p {color:#696969;}
.footers ul {line-height:30px;}

#social-fb:hover {
     color: #3B5998;
     transition:all .001s;
 }
 #social-tw:hover {
     color: #4099FF;
     transition:all .001s;
 }
 #social-gp:hover {
     color: #d34836;
     transition:all .001s;
 }
 #social-em:hover {
     color: #f39c12;
     transition:all .001s;
 }
 #social-fb {
  margin-right: 2px;
}
#social-tw {
  margin-right: 2px;
}
#social-gp {
  margin-right: 2px;
}
#social-em {
  margin-right: 2px;
}
.dgr
{
  color: darkgoldenrod;
}

.loadimg
{
  width: 100%;
}
.my
{
  width: 100%;
}
